import React from 'react'
import { Row, Col, Card } from '@themesberg/react-bootstrap'
import useLivePlayers from '../../hooks/useLivePlayers'
import { SpinnerLoader } from '../../../../../components/Preloader'
import './livePlayer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faMobileAlt, faTabletAlt, faRedo } from '@fortawesome/free-solid-svg-icons'
import { CircleChart } from '../../../../../components/CircleChart'
import { getTextColor } from '../../../../../utils/dashboardEffects'

export default () => {
  const {
    loading,
    livePlayerData,
    fetchDetails
  } = useLivePlayers()

  const icons = { desktop: faDesktop, mobile: faMobileAlt, tablet: faTabletAlt }
  const color = { desktop: 'secondary', mobile: 'primary', tablet: 'tertiary' }

  return (livePlayerData &&
    <Row>
      <Col>
        <Card className='p-2 mb-2 graph-container'>
          <Row>

            <Col>
              <h3 className='d-flex'>Live Player Report {loading ? <SpinnerLoader /> : <FontAwesomeIcon onClick={() => fetchDetails()} style={{ fontSize: '18px', color: 'gray', cursor: 'pointer', marginTop: '10px', marginLeft: '10px' }} icon={faRedo} />}</h3>
            </Col>
            <hr className='hr-line' />
          </Row>

          <Row>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.todayTotalGgr)}>€ {livePlayerData.todayTotalGgr || 0}</h3>
              <span className='gray-text'>TODAY GGR</span>
            </Col>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.totalPlayers)}>{livePlayerData.totalPlayers}</h3>
              <span className='gray-text'>Total Players</span>
            </Col>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.registrationConvRate)}>{livePlayerData.registrationConvRate || 0} %</h3>
              <span className='gray-text'>Registration Conv. Rate</span>
            </Col>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.depositConvRate)}>{livePlayerData.depositConvRate || 0} %</h3>
              <span className='gray-text'>Deposit Conv. Rate</span>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col style={{ maxWidth: '300px' }}>
        <Card className='p-2 mb-2 graph-container'>
          <Row className='d-block d-xl-flex align-items-center'>
            <Col xs={12} xl={5} className='px-xl-0 text-center'>
              <h3 className='green-text'>{livePlayerData?.loggedInPlayer || 0} </h3>
              <p className='gray-text'>Logged In Players</p>
              <CircleChart series={livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => d.count) || []} />
            </Col>
            <Col xs={12} xl={7} className='px-xl-0 text-center'>

              {livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => (
                <p key={`circle-element-${d.device_type}`} className='text-gray device-report'>
                  <FontAwesomeIcon icon={icons[d.device_type]} className={`icon icon-xs text-${color[d.device_type]} w-20 me-1`} />
                  {` ${d.device_type} `}{`${d.count}`}
                </p>
              ))}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>

  )
}
